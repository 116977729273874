import React, { useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/TimpineTherapyLogoFinal1Green.png';
import './NavBar.css';

export default function NavBarMobile(props) {
    const [sideNavOpen, setSideNavOpen] = useState(false);
    let location = useLocation();
    let isHome = location.pathname === '/';
    return (
        <nav className="d-flex align-items-center justify-content-between py-2 px-2">
            { !isHome ?
            <div id="site-logo">
                <Link to="/" className="text">
                    <img src={logo} alt="timpine therapy" width={300} />
              </Link>
            </div>
            :
            <div id="site-logo" />
            }
            <div className="menuIcon">
                <button onClick={ () => setSideNavOpen(!sideNavOpen) }>
                    <FontAwesomeIcon icon={ faBars } size="2x" />
                </button>
            </div>
            <div id="sideNav" className={ `d-flex flex-column ${sideNavOpen ? 'open' : 'closed'}` }>
                <button className="m-3 text-left" onClick={ () => setSideNavOpen(false) }>
                    <NavLink activeClassName="is-active home" to="/" exact>Home</NavLink>
                </button>
                <button className="m-3 text-left" onClick={ () => setSideNavOpen(false) }>
                    <NavLink activeClassName="is-active home" to="/aboutme" exact>About Me</NavLink>
                </button>
                <button className="m-3 text-left" onClick={ () => setSideNavOpen(false) }>
                    <NavLink activeClassName="is-active home" to="/resources" exact>Resources</NavLink>
                </button>
                <button className="m-3 text-left" onClick={ () => setSideNavOpen(false) }>
                    <NavLink activeClassName="is-active home" to="/contact" exact>Contact</NavLink>
                </button>
            </div>
            <div id="sideNavBackground" className={`${sideNavOpen ? 'dark': 'light'}`} onClick={ () => setSideNavOpen(false) }></div>
        </nav>
    )
}