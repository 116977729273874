import React from 'react';
import { Link } from 'react-router-dom';
import './AboutMe.css';
  import roseyProfilePic from '../assets/rosey_profile_pic2.jpeg';
import Text from './Text';

export default function AboutMe(props) {
  return (
    <div id="aboutme" className="page-container">
      <div className={`page-overlay ${props.screenType}`}>
        <div className="description row">
          <div className="col-lg-4">
            <div className='m-auto text-center'>
            <img src={roseyProfilePic} width={`${props.screenType === 'mobile' ? '250' : '330'}`}  alt="rosey bassett" />
            </div>
            <ul className="p-0 creds text-center">
              <li><Text.Normal><a href="mailto:rosey.timpine.therapy@gmail.com">rosey.timpine.therapy@gmail.com</a></Text.Normal></li>
              <li><Text.Normal>801-203-0128</Text.Normal></li>
              <li><Link to="/contact"><Text.Normal>Contact me</Text.Normal></Link></li>
            </ul>
          </div>
          <div className="col">
            <Text.Header size={props.screenType === 'mobile' && 'sm'} {...props}>ROSEY&nbsp; SCHAEFERMEYER, &nbsp;LCSW</Text.Header>
            <Text.Blockquote className="ml-3">
              I love people and am in awe of the resilience of the human experience! I am passionate about EMDR therapy
              because it assists our brains to do what they were designed to do - heal and continually grow and progress.
              I love being able to help people heal areas of their past and present where they feel stuck and then watch
              them continue to grow and thrive.
            </Text.Blockquote>
            <div className="training">
              <Text.Header {...props} size="sm">{ "Training & Credentials" }</Text.Header>
              <ul>
                <li><Text.Normal>Master of Social Work - Brigham Young University (2012)</Text.Normal></li>
                <li><Text.Normal>Domestic Violence Trained (2013)</Text.Normal></li>
                <li><Text.Normal>Dialectical Behavioral Therapy Trained (2015)</Text.Normal></li>
                <li><Text.Normal>EMDR Certified (2015)</Text.Normal></li>
                <li><Text.Normal>Seeking Safety Trained (2017)</Text.Normal></li>
                <li><Text.Normal>EMDR Institute/ HAP Consultant (2018)</Text.Normal></li>
                <li><Text.Normal>  Advanced Specialty EMDR Training in working with relational and attachment issues for both adolescents and adults, dissociative disorders, and ego-state therapy.</Text.Normal></li>
              </ul>
            </div>
            <div className="insurances">
              {/* <Text.Header {...props} size="sm">{"Payment Options"}</Text.Header> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}