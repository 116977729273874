import React, { useState } from 'react';
import Text from './Text';
import { useResizeListener } from '../hooks/useResizeListener';

const calculateIFrameHeight = (screenWidth) => {
    let min = 300
    let max = 500
    let height = Math.abs(screenWidth / 2) - 100
    height = height <= min ? min : height >= max ? max : height
    return height
}
export default function Resources(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useResizeListener(setWindowWidth)
    return (
        <div id="resources" className="page-container">
            <div className={`page-overlay ${props.screenType}`}>
                <Text.Header {...props}>Eye Movement Desensitation Reprocessing (EMDR)</Text.Header>
                <div className="mt-3 row no-gutters">
                    <div className={`col-lg-6 col-sm-12 text-center ${props.screenType !== 'mobile' && 'mr-3'}`}>
                        <iframe
                            width="100%"
                            height={`${calculateIFrameHeight(windowWidth)}`}
                            title="emdrVideo"
                            src="https://www.youtube.com/embed/Pkfln-ZtWeY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                    <div className="col">
                        <Text.Normal>EMDR therapy helps children and adults of all ages.
                        Therapists use EMDR therapy to address a wide range of challenges.
                        </Text.Normal>
                        <div className="mt-2 d-flex justify-content-around">
                            <ul>
                                <li><Text.Normal>Anxiety, panic attacks, and phobias</Text.Normal></li>
                                <li><Text.Normal>Chronic illness and medical issues</Text.Normal></li>
                                <li><Text.Normal>Depression and bipolar disorders</Text.Normal></li>
                                <li><Text.Normal>Dissociative disorders</Text.Normal></li>
                                <li><Text.Normal>Eating disorders</Text.Normal></li>
                                <li><Text.Normal>Grief and loss</Text.Normal></li>
                            </ul>
                            <ul>
                                <li><Text.Normal>Pain</Text.Normal></li>
                                <li><Text.Normal>Performance anxiety</Text.Normal></li>
                                <li><Text.Normal>Personality disorders</Text.Normal></li>
                                <li><Text.Normal>PTSD and other trauma and stress-related issues</Text.Normal></li>
                                <li><Text.Normal>Sexual assault</Text.Normal></li>
                                <li><Text.Normal>Sleep disturbance</Text.Normal></li>
                                <li><Text.Normal>Substance abuse and addiction</Text.Normal></li>
                                <li><Text.Normal>Violence and abuse</Text.Normal></li>
                            </ul>
                        </div>
                    </div>
                </div>
                    <div>
                    <Text.Normal className=" mt-3 mb-3">
                            EMDR therapy does not require talking in detail about the distressing issue or
                            completing homework between sessions. Instead, it focuses on changing the
                            emotions, thoughts, or behaviors resulting from the distressing issue, thus allowing the brain to
                            resume its natural healing process.  The goal is to heal the past in order to create a better present and more hopeful future regarding your symptoms.
                        </Text.Normal>
                        <Text.Normal>
                            EMDR therapy is designed to resolve unprocessed traumatic memories in the brain. It is not uncommon for EMDR therapy to be completed in fewer sessions than other psychotherapies.

                        </Text.Normal>
                    </div>
            </div>
        </div>
    )
}