import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './components/Home';
import HomeMobile from './components/HomeMobile'
import AboutMe from './components/AboutMe';
import Contact from './components/Contact';
import MakeAppointment from './components/MakeAppointment';
import IntakeForms from './components/IntakeForms';
import Resources from './components/Resources';

export default function Routes(props) {
    return (
        <Switch>
            <Route path="/aboutme">
                <AboutMe {...props}/>
            </Route>
            <Route path="/contact">
                <Contact {...props} />
            </Route>
            <Route path="/makeappointment">
                <MakeAppointment {...props} />
            </Route>
            <Route path="/intakeforms">
                <IntakeForms {...props} />
            </Route>
            <Route path="/resources">
                <Resources {...props}/>
            </Route>
            <Route path="/">
                {
                    props.screenType==='mobile' ?
                    <HomeMobile {...props}/>
                    :
                <Home {...props}/>
                }
            </Route>
        </Switch>
    )
}