import React, { useState } from 'react';
import { Map } from 'pigeon-maps';
import Marker from 'pigeon-marker';
import mapTiler from '../config/mapTiler.json';
import config from '../config/emailjs.json';
import emailjs from 'emailjs-com';
import './Contact.css';
import Text from './Text';
import { useResizeListener } from '../hooks/useResizeListener';

const calculateMapDimensions = (screenWidth) => {
    let min = 300
    let minH = 200
    let max = 700
    let maxH = 500
    let width = Math.abs(screenWidth / 2)
    let height = width - 200
    width = width <= min ? min : width >= max ? max : width
    height = height <= minH ? minH : height >= maxH ? maxH : height
    return [width, height]
}

export default function Contact({ screenType }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [zoom, setZoom] = useState(16);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailSent, setEmailSent] = useState('');

    const [mapWidth, mapHeight] = calculateMapDimensions(windowWidth)

    useResizeListener(setWindowWidth)

    const mapTilerProvider = (x, y, z, dpr) => {
        return `https://api.maptiler.com/maps/streets/256/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png?key=${mapTiler.key}`;
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            setSendingEmail(true);
            try {
                await emailjs.sendForm('gmail', 'web_page_contact', e.target, config.userId);
                setEmailSent("Your message has been sent. We'll reach out to you as soon as possible.");
                document.getElementById('contactForm').reset();
            } catch (err) {
                setEmailSent(`There was an error while sending the email ${err}`)
                setSendingEmail(false);
            }
        } catch (err) {
            console.log('error', err)
        }

    }

    return (
        <div id="contact" className="page-container">
            <div className={`page-overlay ${screenType}`}>
                <Text.Header screenType={screenType}>CONTACT ME</Text.Header> 
                <div className="row">
                    <div className="map col-lg-7 col-sm-12" style={ { maxWidth: mapWidth + 30 } }>
                        <Text.Normal>Rosey Schaefermeyer, LCSW</Text.Normal>
                        <Text.Normal>rosey.timpine.therapy@gmail.com</Text.Normal>
                        <Text.Normal>801-203-0128</Text.Normal>
                        <Text.Normal>4055 South 700 East ste 101D Millcreek, UT 84107</Text.Normal>
                        <div className="text-center">
                        <Map
                            center={ [40.683480, -111.871060] }
                            zoom={ zoom }
                            width={ mapWidth }
                            height={ mapHeight }
                            provider={ mapTilerProvider }
                            dprs={ [1, 2] }>
                            <Marker anchor={ [40.683480, -111.871060] } payload={ 1 } />
                        </Map>
                        </div>
                        <div className="text-right zoomBtns">
                            <button onClick={ () => setZoom(zoom + 1) }>+</button>
                            <button onClick={ () => setZoom(zoom - 1) }>&mdash;</button>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <form id="contactForm" onSubmit={sendEmail}>
                            <div className="form-group">
                                <label htmlFor="nameField"><Text.Normal>Name</Text.Normal></label>
                                <input required type="text" className="form-control" id="nameField" name="contactName" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneField"><Text.Normal>Phone Number</Text.Normal></label>
                                <input required type="text" className="form-control" id="phoneField" name="contactPhone" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailField"><Text.Normal>Email</Text.Normal></label>
                                <input required type="email" className="form-control" id="emailField" name="contactEmail" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="messageField"><Text.Normal>Message</Text.Normal></label>
                                <textarea required className="form-control" id="messageField" rows="10" name="contactMessage" />
                            </div>
                            <div><Text.Normal size="sm">{emailSent}</Text.Normal></div>
                            <button disabled={sendingEmail} type="submit" className="btn btn-primary">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}