import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Text from './Text';
import logo from '../assets/TimpineTherapyLogoFinal1Green.png';
import forestVideo from '../assets/forestVidCompressed.m4v';

export default function Home(props) {
    return (
        <div className="">
            <video autoPlay muted loop playsInline preload="auto" className="videoBg">
                <source src={forestVideo} ></source>
            </video>
            <div id="home" className="text-center">
                <Fade bottom duration={3000}>
                    <img src={logo} alt="timpine therapy. heal. grow. thrive." width={1000} />
                </Fade>
                <Zoom duration={ 2000 } delay={ 500 }>
                    <div className="front-btns mt-5">
                        <Link to="/aboutme"><Text.Normal size="lg" {...props}>ABOUT ME</Text.Normal></Link>
                        <Link to="/resources"><Text.Normal size="lg" {...props}>RESOURCES</Text.Normal></Link>
                        <Link to="/contact"><Text.Normal size="lg" {...props}>CONTACT</Text.Normal></Link>
                    </div>
                </Zoom>
            </div>
        </div>
    )
}