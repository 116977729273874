import React from 'react';
import { NavLink, Link, useLocation} from 'react-router-dom';
import logo from '../assets/TimpineTherapyLogoFinal1Green.png';
import Fade from 'react-reveal/Fade';
import './NavBar.css';

export default function NavBarDesktop(props) {
    let location = useLocation();
    let isHome = location.pathname === '/';
    return (
        <nav id="nav-bar" className="d-flex align-items-center justify-content-between mx-0">
            { !isHome ?
            <Fade duration={1000}>
                <div id="site-logo" className="mx-3">
                    <Link to="/">
                        <img src={logo} alt="timpine therapy" width={300} />
                    </Link>
                </div>
            </Fade>
                :
                <div id="site-logo"></div>
            }
            <div className={`btn-group`}>
                <div className="link mx-4 p-3">
                    <NavLink activeClassName="is-active home" to="/" exact>Home</NavLink>
                </div>
                <div className="link mx-4 p-3">
                    <NavLink activeClassName="is-active" to="/aboutme">About Me</NavLink>
                </div>
                <div className="link mx-4 p-3">
                    <NavLink activeClassName="is-active" to="/resources">Resources</NavLink>
                </div>
                <div className="link mx-4 p-3">
                    <NavLink activeClassName="is-active" to="/contact">Contact</NavLink>
                </div>
            </div>
        </nav>
    )
}