import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import NavBarMobile from './components/NavBarMobile';
import Routes from './Routes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarDesktop from "./components/NavBarDesktop";
import ReactGA from 'react-ga';

/**
 * TO DO
 * Get links on pages
 * set up intake form
 */

export default function App() {
  const [screenType, setScreenType] = useState();
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const isMobile = useMediaQuery({ maxWidth: 767 })

  ReactGA.initialize('G-1J8GHE8PY6');

  useEffect(() => {
    if (isDesktop) setScreenType('desktop')
    else if (isTablet) setScreenType('tablet')
    else if (isMobile) setScreenType('mobile')
  }, [isDesktop, isTablet, isMobile])

  return (
    <Router>
      <div id="body-container">
        { screenType === 'mobile' ?
          <NavBarMobile />
          :
          <NavBarDesktop />

        }
          <Routes screenType={ screenType } /> 
      </div>
    </Router>
  );
}