import React from 'react'
import './Text.css'

const Text = {
    Header: function Header({ customStyle, className, size, span, screenType, children }) {
        const isMobile = screenType === 'mobile';
        const fontSizes = {
            'x-lg': isMobile ? '60px' : '110px',
            'lg': isMobile ? '30px' : '60px',
            'sm': isMobile ? '22px' : '35px',
            'x-sm': isMobile ? '15px' : '20px',
        }
        
        return (
        <div 
            className={ className } 
            style={{ 
                fontSize: fontSizes[size]  || (isMobile ? '25px': '45px'),
                fontWeight: (customStyle && customStyle.fontWeight) || '300', 
                ...customStyle 
            }}
            >
            { children }
        </div>
        )
    },
    Normal: function Normal({ customStyle, className, size, span, children}) {
        const fontSizes = {
            'lg': '25px',
            'sm': '12px',
        }
        return <div className={className} style={{ fontSize: fontSizes[size] || '16px', ...customStyle}}>{children}</div>
    },
    Blockquote: function Blockquote({className, size, children}) {
        return (
            <div className={className}>
            <blockquote>
                <p className="mb-0"><q><i>{children}</i></q></p>
            </blockquote>
            </div>
        )
    }
}

export default Text